<template>
  <div class="container-fluid py-4 add-student add-course">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">
                تعديل بيانات الدورة
              </h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <form @submit="createCourse" role="form" class="text-start mt-3">
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label
                    >اسم الدورة<span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.name ? errors.name[0] : ""
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="text"
                    placeholder="اسم الدورة"
                    v-model="course.name"
                  />
                </div>
                <div class="mb-3 col-lg-6">
                  <label>
                    اسم الدورة بالإنجليزية<span class="green">(اختياري) </span>
                    <span color="danger" class="error">{{
                      errors.name_en ? errors.name_en[0] : ""
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="text"
                    placeholder="
                    الاسم  بالإنجليزي"
                    v-model="course.name_en"
                  />
                </div>
              </div>
              <div class="row">
                <h6 style="text-align: right" class="text-warning text-right">
                  يجب اختيار الترم ثم الفرقة لظهور المادة المناسبة
                </h6>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    السنة الدراسية<span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.academic_year_id ? errors.academic_year_id[0] : ""
                    }}</span></label
                  >
                  <div class="input-group input-group-outline null">
                    <select class="form-control" v-model="SelectedYear.id">
                      <option selected value="">اختر السنة الدراسية</option>
                      <option
                        v-for="year in academicYear"
                        :key="year.id"
                        :value="year.id"
                      >
                        {{ year.name }}
                      </option>
                    </select>
                    <i class="fas fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="mb-3 col-lg-6">
                  <label>
                    الترم الدراسي<span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.semester_id ? errors.semester_id[0] : ""
                    }}</span>
                  </label>
                  <div class="input-group input-group-outline null">
                    <select
                      class="form-control"
                      v-model="SelectedSemester"
                      @change="removeGrade"
                      required
                    >
                      <option selected value="">اختر الترم الدراسي</option>
                      <option value="1">الأول</option>
                      <option value="2">الثاني</option>
                      <option value="2">مستمر</option>
                    </select>
                    <i class="fas fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    الفرقة <span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.grade_id ? errors.grade_id[0] : ""
                    }}</span></label
                  >
                  <multiselect
                    class="required form-control"
                    id="group"
                    v-model="selectedGrade"
                    :options="grades"
                    :searchable="true"
                    :close-on-select="true"
                    placeholder="  اختر الفرقة"
                    label="name"
                    @select="getSubjects($event)"
                    :custom-label="customLabel"
                    track-by="id"
                    required="required"
                    :internal-search="true"
                  >
                    >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد نتيجة </span>
                    </template>
                  </multiselect>
                </div>
                <div class="mb-3 col-lg-6">
                  <label>
                    المواد <span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.subject_id ? errors.subject_id[0] : ""
                    }}</span></label
                  >
                  <multiselect
                    class="required form-control"
                    id="area"
                    v-model="selectedSubject"
                    :options="subjects"
                    :searchable="true"
                    :close-on-select="true"
                    placeholder="اختر المادة"
                    label="name"
                    track-by="id"
                    required="required"
                    :internal-search="true"
                    @select="setCourseCost"
                  >
                    >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد نتيجة </span>
                    </template>
                  </multiselect>
                  <i class="fas fa-caret-down" aria-hidden="true"></i>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    عدد المحاضرات <span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.no_of_lectures ? errors.no_of_lectures[0] : ""
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="text"
                    placeholder=" عدد المحاضرات"
                    v-model="course.no_of_lectures"
                  />
                </div>
                <div class="mb-3 col-lg-6" v-if="!course.free">
                  <label>
                    قيمة المحاضرة <span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.lecture_cost ? errors.lecture_cost[0] : ""
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="text"
                    placeholder="قيمة المحاضرة"
                    v-model="course.lecture_cost"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label>
                    المدرس <span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.instructor_id ? errors.instructor_id[0] : ""
                    }}</span></label
                  >
                  <multiselect
                    class="required form-control"
                    id="group"
                    v-model="selectedInstructor"
                    :options="instructors"
                    :searchable="true"
                    :close-on-select="true"
                    placeholder="  اختر  المدرس"
                    label="name"
                    :custom-label="customLabel"
                    track-by="id"
                    required="required"
                    :internal-search="true"
                  >
                    >
                    <template v-slot:noOptions>
                      <span> فارغة </span>
                    </template>
                    <template v-slot:noResult>
                      <span>لا يوجد نتيجة </span>
                    </template>
                  </multiselect>
                </div>
                <div class="mb-3 mt-4 col-lg-2">
                  <vmd-checkbox
                    id="free-video"
                    type="text"
                    v-model="course.free"
                    :value="true"
                  >
                    كورس مجاني
                  </vmd-checkbox>
                </div>
                <div class="mb-3 col-lg-3" v-if="!course.free">
                  <label>
                    قيمة الدورة <span class="red">*</span>
                    <span color="danger" class="error">{{
                      errors.total_cost ? errors.total_cost[0] : ""
                    }}</span></label
                  >
                  <vmd-input
                    id=""
                    type="text"
                    placeholder="قيمة الدورة"
                    required
                    v-model="course.total_cost"
                  />
                </div>
              </div>
              <div class="row"></div>
              <div class="row">
                <div class="mb-3 col-lg-6 text-center" v-if="course.photo_url">
                  <label for="image" class="required"> صورة الدورة </label>
                  <img :src="course.photo_url" width="200px" height="200px" />
                </div>
                <div class="mb-3 col-lg-6">
                  <label>
                    {{ course.photo_url ? "تغيير الصورة" : "صورة الدورة" }}
                    <span color="danger" class="error">{{
                      errors.photo ? errors.photo[0] : ""
                    }}</span></label
                  >
                  <vmd-input id="image" type="file" @change="onFileChange" />
                </div>
                <div class="mb-3 col-lg-6">
                  <label>
                    رسوم التطبيق <span class="red">*</span>
                    <span color="danger" class="error"></span
                  ></label>
                  <vmd-input
                    id=""
                    type="text"
                    placeholder="  رسوم التطبيق"
                    v-model="course.app_fees"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button class="my-4 mb-2" variant="gradient" color="info">
                    حفظ</vmd-button
                  >
                </div>
                <div class="text-center col-lg-6">
                  <router-link to="/courses">
                    <vmd-button
                      class="my-4 mb-2"
                      variant="gradient"
                      type="reset"
                      color="warning"
                    >
                      رجوع</vmd-button
                    >
                  </router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import VmdCheckbox from '@/components/VmdCheckbox.vue'
import swal from 'sweetalert'
import $ from 'jquery'
export default {
  name: 'AddCourse',
  components: {
    VmdInput,
    VmdButton,
    VmdCheckbox
  },
  data () {
    return {
      course: {},
      subjects: [],
      grades: [],
      instructors: [],
      academicYear: [],
      branches: [],
      selectedGrade: '',
      selectedSubject: '',
      selectedInstructor: '',
      SelectedYear: '',
      SelectedSemester: '',
      image: '',
      errors: []
    }
  },
  created () {
    this.$http
      .get(`${this.$hostUrl}courses/${this.$route.params.id}`)
      .then((response) => {
        this.course = response.data.data
        this.course.free = response.data.data.free === 1
        // alert(this.course.type)
        this.course.type = this.course.type === 1
        this.selectedGrade = {
          id: this.course.grade_object.id,
          name: this.course.grade_object.name
        }
        this.selectedSubject = {
          id: this.course.subject_object.id,
          name: this.course.subject_object.name
        }
        this.selectedInstructor = {
          id: this.course.instructor_object.id,
          name: this.course.instructor_object.name
        }
        this.SelectedYear = {
          id: this.course.academic_year_object.id,
          name: this.course.academic_year_object.name
        }
        this.SelectedSemester = this.course.subject_object.semester
        const subjects = []
        this.$http
          .get(
            `${this.$hostUrl}grade/${this.selectedGrade.id}/subjects?semester=${
              this.SelectedSemester !== '3' ? this.SelectedSemester : ''
            }`
          )
          .then((response) => {
            $.each(response.data.data, function (key, value) {
              subjects.push({
                name: value.name,
                id: value.id,
                cost: value.cost
              })
            })
            this.subjects = subjects
          })
      })
    const grades = []
    this.$http.get(`${this.$hostUrl}grades`).then((response) => {
      $.each(response.data.data, function (key, value) {
        grades.push({
          name: value.name,
          id: value.id
        })
      })
      this.grades = grades
    })
    const instructors = []
    this.$http.get(`${this.$hostUrl}instructors`).then((response) => {
      $.each(response.data.data, function (key, value) {
        instructors.push({
          name: value.name,
          id: value.id
        })
      })
      this.instructors = instructors
    })
    const academicYear = []
    this.$http.get(`${this.$hostUrl}academic-years`).then((response) => {
      $.each(response.data.data, function (key, value) {
        academicYear.push({
          name: value.name,
          id: value.id
        })
      })
      this.academicYear = academicYear
    })
  },
  methods: {
    removeGrade () {
      this.selectedGrade = ''
    },
    getSubjects ($event) {
      this.selectedSubject = ''
      const subjects = []
      this.$http
        .get(
          `${this.$hostUrl}grade/${$event.id}/subjects?semester=${this.SelectedSemester}`
        )
        .then((response) => {
          $.each(response.data.data, function (key, value) {
            subjects.push({
              name: value.name,
              id: value.id,
              cost: value.cost
            })
          })
          this.subjects = subjects
        })
    },
    setCourseCost (event) {
      this.course.total_cost = event.cost
    },
    onFileChange (e) {
      const _URL = window.URL || window.webkitURL
      this.image = e.target.files[0]
      let file = ''
      if ((file = e.target.files[0])) {
        const img = new Image()
        img.src = _URL.createObjectURL(file)
      }
    },
    async createCourse (e) {
      e.preventDefault()
      const formData = new FormData()
      if (/^\d+$/.test(this.course.name)) {
        swal('اسم الدورة يجب ان يحتوي على حروف')
        return 0
      }
      formData.append('name', this.course.name)
      if (this.course.name_en) {
        formData.append('name_en', this.course.name_en)
      }
      formData.append('grade_id', this.selectedGrade.id)
      formData.append('subject_id', this.selectedSubject.id)
      formData.append('instructor_id', this.selectedInstructor.id)
      formData.append('academic_year_id', this.SelectedYear.id)
      formData.append('no_of_lectures', this.course.no_of_lectures)
      formData.append(
        'total_cost',
        this.course.free
          ? 0 : this.course?.total_cost
      )
      formData.append('free', this.course.free ? 1 : 0)
      if (this.course.lecture_cost) {
        formData.append(
          'lecture_cost',
          this.course.free ? 0 : this.course.lecture_cost
        )
      }
      formData.append('_method', 'PUT')
      formData.append('type', 1)
      if (this.image) {
        formData.append('photo', this.image)
      }
      if (this.course.app_fees != null) {
        formData.append('app_fees', this.course.app_fees)
      }

      this.errors = await this.postRequest(
        formData,
        this.$hostUrl + 'courses/' + this.$route.params.id,
        '/courses'
      )
    }
  }
}
</script>
<style>
img {
  width: 200px !important;
  height: 200px !important;
  border-radius: 10px;
}
</style>
